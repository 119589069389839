var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Card",
    { style: { backgroundColor: _vm.backgroundColor }, attrs: { padding: 0 } },
    [
      _c(
        "div",
        {
          staticClass: "card-content card2",
          style: { backgroundImage: _vm.backgroundImage },
        },
        [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "div",
                { staticClass: "card-title", style: { color: _vm.color } },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _c(
                "div",
                { staticClass: "card-data" },
                [
                  _c("count-up", {
                    attrs: {
                      prefix: _vm.prefix,
                      suffix: _vm.suffix,
                      id: _vm.id,
                      "end-val": _vm.endVal,
                      color: _vm.dataColor,
                      countSize: _vm.countSize,
                      countWeight: _vm.countWeight,
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "card-description",
                      style: {
                        color: _vm.descriptionColor,
                        fontSize: _vm.descriptionSize,
                        fontWeight: _vm.descriptionWeight,
                      },
                    },
                    [_vm._v(_vm._s(_vm.description))]
                  ),
                ],
                1
              ),
              _c("Progress", {
                attrs: {
                  percent: _vm.endVal,
                  "stroke-color": _vm.color,
                  "stroke-width": _vm.strokeWidth,
                  "hide-info": "",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }